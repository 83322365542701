import { DOMEmit } from '../../features/dom_utils'
import {
  type IQuestionnaireStore,
  type QStoreContext,
  type SmartStore
} from '../../features/QuestionnaireUtils'
import Utils from '../../features/utils'
const axios = Utils.axios

export const fetchLiveDemoData = ({
  state,
  getters,
  commit,
  dispatch
}: QStoreContext) => {
  const dependentAtts = getDependentAtts(state, getters)
  dependentAtts.map(({ att, succession }) => void dispatch('fetch_datasheet_values', { att, succession }))
  const params = {
    show_cond_logic: state.showConditionLogic,
    entries: state.entries,
    asts: getters.questionAndOperationAsts,
    loop_counts: state.loop_counts,
    operations:
      state.operations
        ?.map((operation) => `\`${operation.att} ${operation.cond}\``)
        .join('\n') || []
  }

  interface ILiveEntriesResponse {
    localized_entries: Backend.Questionnaire.Entries
    entries: Backend.Questionnaire.Entries
    html: string
    conditions: Backend.Questionnaire.ValidConditions
  }

  void axios
    .post<ILiveEntriesResponse>(
      `/templates/${AvvStore.state.template_id}/live_entries`,
      params
    )
    .then((response) => response.data)
    .then((data) => {
      const questionnaire_data = {
        entries_values_localized: data.localized_entries,
        entries_values: data.entries,
        valid_conditions: data.conditions
      }
      state.changedEntries = []
      commit('SET_QUESTIONNAIRE_DATA', questionnaire_data)
      DOMEmit('live-demo-html-update', { html: data.html })
      return
    })
}

const getDependentAtts = (
  state: IQuestionnaireStore,
  getters: SmartStore<IQuestionnaireStore>['getters']
) => {
  return state.changedEntries
    .map(({ att, succession }) => {
      const dependentQuestions = getters.nonDeletedQuestions.filter(
        (depQ: Backend.Questionnaire.IQuestion) =>
          depQ.opts.datasheet_dependencies?.some((dep) => dep.attribute === att)
      )
      return dependentQuestions.map((depQ: Backend.Questionnaire.IQuestion) => {
        return { att: depQ.att, succession }
      })
    })
    .flat()
}
